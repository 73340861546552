/**
 * @license
 * Copyright 2020 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import {Unpack, UnpackAttrs} from '../kernel_names';
import {GradConfig, NamedAttrMap} from '../kernel_registry';
import {stack} from '../ops/stack';
import {Tensor} from '../tensor';

export const unpackGradConfig: GradConfig = {
  kernelName: Unpack,
  gradFunc: (dy: Tensor[], saved: Tensor[], attrs: NamedAttrMap) => {
    const unpackAttrs: UnpackAttrs = attrs as {} as UnpackAttrs;
    const {axis} = unpackAttrs;
    return {value: () => stack(dy, axis)};
  }
};
